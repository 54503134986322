import {authHeader, getApiDomain} from "../contexts/Auth";

export function updateItemInArray(items, key, match, attributes) {
  let index = items.findIndex(x=> x[key] === match);
  if (index === -1) {
    return [
      ...items,
      Object.assign({}, {[key]: match}, attributes),
    ];
  }
  else {
    return [
      ...items.slice(0, index),
      Object.assign({}, items[index], attributes),
      ...items.slice(index + 1),
    ];
  }
}

export const joditConfig = {
  width: 'auto',
  height: 'auto',
  defaultTimeout: 1000,
  license: process.env.REACT_APP_JODIT_LICENSE || 'development',
  "buttons": "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,font,fontsize,paragraph,superscript,subscript,image,video,file,cut",
  filebrowser: {
    moveFolder: true,
    moveFile: true,
    ajax: {
      url: getApiDomain() + '/files/assets',
      headers: authHeader(),
    },
  },
  uploader: {
    url: getApiDomain() + '/upload/assets',
    headers: authHeader(),
  },
};

export function getFontColorFromColor(hexColor) {
  hexColor = hexColor.replace("#", "");
  var r = parseInt(hexColor.substring(0, 2), 16);
  var g = parseInt(hexColor.substring(2, 4), 16);
  var b = parseInt(hexColor.substring(4, 6), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}
